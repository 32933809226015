import React, { useContext, useEffect } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux' ;
import Splace from "./pages/layouts/Splace";
import SplashSlider from "./pages/layouts/SplashSlider";
import Auth from "./pages/layouts/Auth";
import Login from "./pages/layouts/Login";
import ForgotPassword from "./pages/layouts/ForgotPassword";
import Register from "./pages/layouts/Register";
import VerifyEmail from "./pages/layouts/VerifyEmail";
import NotFound from "./pages/components/NotFound";
import Home from "./pages/components/Home";
import Auction from "./pages/components/Auction";
import AboutCause from "./pages/components/AboutCause";
import AboutDonor from './pages/components/organisms/AboutDonor/AboutDonor.jsx'
import AuctionOverview from "./pages/components/AuctionOverview";
import AuthContext from "./store/auth-context";
import CauseContext from "./store/cause-context";
import AuctionContext from "./store/auction-context";
import CircularProgress from "@mui/material/CircularProgress";
import AuctionMain from "./pages/components/AuctionMain";
import HistoryComp from "./pages/components/History";
import MyBids from "./pages/components/MyBids";
import Account from "./pages/components/Account";
import BidConfirmation from "./pages/components/BidConfirmation";
import CongratulationsScreen from "./pages/components/CongratulationsScreen";
import CaptureCardDetails from "./pages/components/AuthorizePayment";
import AuctionItemList from "./pages/components/AuctionItemList";
import FaqPage from "./pages/components/Faq/Faq";
import SupportPage from "./pages/components/Support/Support";
import PrivacyPage from "./pages/components/Privacy"
import TermsPage from "./pages/components/Terms";
import Search from "./pages/components/organisms/Search";
import SearchResult from "./pages/components/organisms/SearchResult/SearchResult";
import BrowseItems from "./pages/components/organisms/BrowseItems/BrowseItems";
import Splash from "./pages/layouts/Home";
import ItemBidHistory from "./pages/components/ItemBidHistory";
import ShippingInformation from "./pages/components/ShippingInformation";
import BuyNowCheckout from "./pages/components/BuyNowCheckout";
import TrackOrder from "./pages/components/TrackOrder";
import ItemDetails from "./pages/components/organisms/ItemDetails/ItemDetails";
import ItemsBag from "./pages/components/ItemsBag";
import About from "./pages/components/About/About";
import Event from "./pages/components/organisms/event/event";
import { getCartItems } from "./redux/cartReducer";
import CartOverView from "./pages/components/organisms/Cart/CartOverview";
import CartCheckout from "./pages/components/organisms/Cart/Cart";
import BrandPartner from "./pages/components/organisms/BrandPartner/brandPartner";
import ExistingPayment from "./pages/components/ExistingPayment/ExistingPayment";
import { useMediaQuery } from "react-responsive";
import DeployedInfo from "./pages/components/DeploymentStatus/DeployedInfo";
import LetsStartFunding from "./pages/components/LetsStartFunding/LetsStartFunding";
import CreateEvent from "./pages/components/organisms/CreateEvent/CreateEvent";
import ViewBlog from "./pages/components/organisms/ViewBlog/ViewBlog";
import ComingSoon from "./pages/components/organisms/ComingSoon/ComingSoon";
import Preferences from "./pages/components/Preferences/Preferences";
import NonProfit from "./pages/components/non-profit/nonProfit";
import Brand from "./pages/components/BrandPage/Brand";
import ShopNow from "./pages/components/organisms/ShopNow/ShopNow";
import LearnMore from "./pages/components/organisms/Impact/Impact";
import RetryPayment from "./pages/layouts/retryPayment";
import BrowseCauses from "./pages/components/organisms/BrowseCauses/BrowseCauses";
import BrowseBrands from "./pages/components/organisms/BrowseBrands/BrowseBrands";
import BrowseStores from "./pages/components/organisms/BrowseStores/BrowseStores";
import CondensedShopNow from "./pages/components/organisms/CondensedShopNow/CondensedShopNow";
import EventBrowseItems from "./pages/components/organisms/EventBrowseItems/EventBrowseItems";
import Blog from "./pages/components/organisms/Blog/Blog.jsx";
import { getFavouriteItems } from "./redux/favouritesReducer.js";
import Favourites from "./pages/components/organisms/Favourites/Favourites.jsx";
import CreateNonProfit from "./pages/components/organisms/CreateNonProfit/CreateNonProfit.jsx";
import ShopNowV3 from "./pages/components/organisms/CondensedShopNow/ShopNowV3/ShopNowV3.jsx";
import { mobileStyle } from "./pages/components/Home";
import EventPage from "./pages/components/organisms/EventPage/EventPage.jsx";

const LoginGuest = ({children}) => {
  localStorage.setItem("guest_user_visited", true)
  return <Navigate to="/app" replace />
}

const NotGuest = ({Page}) => {
  localStorage.setItem("guest_user_visited", true)
  return <Page/>
}

const TermsGuest = () => {
  localStorage.setItem("guest_user_visited", true)
  return <Navigate to="/tos" replace />
}

export default function Router() {
  const ctx = useContext(AuthContext);
  const causeCtx = useContext(CauseContext);
  const auctionCxt = useContext(AuctionContext);
  const dispatch =  useDispatch();
  let guest_user_visited = JSON.parse(localStorage.getItem('guest_user_visited'));

  const Path = useLocation();

  useEffect(() => {
    if (Path.pathname === "/splash") {
      document.documentElement.style.setProperty("--display-app", "block");
      document.documentElement.style.setProperty(
        "--splash-bg",
        "linear-gradient(45deg, rgba(228,133,75,1) 0%, rgba(224,118,60,1) 97%)"
      );
    } else {
      document.documentElement.style.setProperty("--display-app", "none");
    }
  }, [Path.pathname]);

  useEffect(()=>{
    dispatch(getCartItems({loggedIn: ctx.isLoggedIn}))
    if(!ctx.isLoggedIn) return
    dispatch(getFavouriteItems())
  },[ctx.isLoggedIn])

  const isDesktop = useMediaQuery({
    minWidth: 768
  })

  const loader = (
    <div
      style={{
        position: "fixed",
        display: "flex",
        WebkitBoxAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        right: "0px",
        bottom: "0px",
        top: "0px",
        left: "0px",
        backgroundColor: "#00000080",
        color: "#fff",
        zIndex: "1201",
        WebkitTapHighlightColor: "transparent",
      }}
    >
      <CircularProgress color="inherit" />
    </div>
  );

  const routes = useRoutes([
    {
      path: "/home",
      element: ctx.isLoggedIn || guest_user_visited ? (
        <Navigate to="/app" />
      ) : (
        <LoginGuest />
      ),
    },
    {
      path: "",
      element: ctx.isLoggedIn || guest_user_visited ? <Home causeCategoryList={causeCtx.causeCategoryList} auctionCategoryList={auctionCxt.auctionCategoryList} />: <LoginGuest />
      // <Home causeCategoryList={causeCtx.causeCategoryList} auctionCategoryList={auctionCxt.auctionCategoryList} /> : <LoginGuest />
    },
    {
      path: "event/:auctionId",
      element: ctx.isLoggedIn || guest_user_visited ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <NotGuest Page={EventBrowseItems} />, //<LoginGuest />,
      // element: ctx.isLoggedIn || guest_user_visited ? <EventPage /> : <NotGuest Page={Event} />, // <LoginGuest />,
    },
    {
      path: "event/:auctionId/browse-items",
      element: ctx.isLoggedIn || guest_user_visited ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <LoginGuest />,
    },
    {
      path: "marketplace/:auctionId",
      // element: ctx.isLoggedIn || guest_user_visited ? <Event /> : <LoginGuest />,
      element: ctx.isLoggedIn || guest_user_visited ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <NotGuest Page={EventBrowseItems} />, //<LoginGuest />,
    },
    {
      path: "store/:auctionId",
      // element: ctx.isLoggedIn || guest_user_visited ? <Event /> : <LoginGuest />,
      element: ctx.isLoggedIn || guest_user_visited ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <NotGuest Page={EventBrowseItems} />, //<LoginGuest />,
    },
    {
      path: "/",
      children: [
        {
          path: "app",
          element: <Splace />
        },
        {
          path: "splash",
          element: <Splash />
        },
        {
          path: "splace-slider",
          element: !ctx.isLoggedIn && !guest_user_visited && !isDesktop ? <SplashSlider /> : <Navigate to="/" replace />
        },
        // {
        //   path: "home",
        //   element: ctx.isLoggedIn || guest_user_visited ? <Home causeCategoryList={causeCtx.causeCategoryList} auctionCategoryList={auctionCxt.auctionCategoryList} />: <LoginGuest />
        //   // <Home causeCategoryList={causeCtx.causeCategoryList} auctionCategoryList={auctionCxt.auctionCategoryList} /> : <LoginGuest />
        // },
        {
          path: "blog",
          element: <Blog /> 
        },
        {
          path: "blog/:id",
          element: <ViewBlog /> 
        },
        {
          path: "auth",
          element: !ctx.isLoggedIn ? <Auth /> : <Navigate to="/" replace />,
        },
        {
          path: "reset-pwd",
          element: !ctx.isLoggedIn ? (
            <ForgotPassword />
          ) : (
            <Navigate to="/" replace />
          ),
        },
        {
          path: "login",
          element: !ctx.isLoggedIn ? (
            <Login />
          ) : (
            <Navigate to="/" replace />
          ),
        },
        {
          path: "register",
          element: !ctx.isLoggedIn ? (
            <Register />
          ) : (
            <Navigate to="/" replace />
          ),
        },
        {
          path: "auth/verify-email",
          element: !ctx.isLoggedIn ? (
            <VerifyEmail />
          ) : (
            <Navigate to="/" replace />
          ),
        },
        {
          path: "auction",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AuctionMain />
          ) : (
            <LoginGuest />
          ),
        },
        {
          path: 'shop-now',
          element:ctx.isLoggedIn || guest_user_visited ?(
            // <CondensedShopNow/>
            <ShopNowV3/>
          ) : (
            <LoginGuest />
          )
        },
        // {
        //   path: 'shop-now-v2',
        //   element:ctx.isLoggedIn || guest_user_visited ?(
        //     <ShopNow/>
        //   ) : (
        //     <LoginGuest />
        //   )
        // },
        {
          path: 'browse-causes',
          element:ctx.isLoggedIn || guest_user_visited ?(
            <BrowseCauses/>
          ) : (
            <LoginGuest />
          )
        },
        {
          path: 'browse-brands',
          element:ctx.isLoggedIn || guest_user_visited ?(
            <BrowseBrands/>
          ) : (
            <LoginGuest />
          )
        },
        {
          path: 'browse-stores',
          element:ctx.isLoggedIn || guest_user_visited ?(
            <BrowseStores/>
          ) : (
            <LoginGuest />
          )
        },
        {
          path: "auction-items",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AuctionItemList />
          ) : (
            <LoginGuest />
          ),
        },
        {
          path: "auction/:auctionId",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <Auction />
          ) : (
            <LoginGuest />
          ),
        },
        {
          path: "about-cause/:causeId",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AboutCause />
          ) : (
            <LoginGuest />
          ),
        },
        {
          path: "about-donor/:donorId",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AboutDonor />
          ) : (
            <LoginGuest />
          ),
        },
        {
          path: "event-overview/:auction_id",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AuctionOverview />
          ) : (
            // <LoginGuest />
            <NotGuest Page={AuctionOverview}/>
          ),
        },
        {
          path: "marketplace-overview/:auction_id",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AuctionOverview />
          ) : (
            // <LoginGuest />
            <NotGuest Page={AuctionOverview}/>
          ),
        },
        {
          path: "store-overview/:auction_id",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <AuctionOverview />
          ) : (
            // <LoginGuest />
            <NotGuest Page={AuctionOverview}/>
          ),
        },
        {
          path: "lot/:itemId",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <ItemDetails />
            ) : (
              // <LoginGuest to={Path.pathname} />
              <NotGuest Page={ItemDetails} />
          ),
        },
        {
          path: "lot/auction/:auctionId/item/:itemId/history",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <ItemBidHistory />
          ) : (
            <LoginGuest />
          ),
        },
        {
          path: "lot/:itemId/place-bid",
          element: ctx.isLoggedIn ? (
            <BidConfirmation />
          ) : (
            <Navigate to="/login" replace />
          ),
        },
        {
          path: "congrats",
          element: ctx.isLoggedIn ? <CongratulationsScreen /> : <Navigate to="/login" replace />,
        },
        {
          path: "account",
          element: ctx.isLoggedIn || guest_user_visited ? (
            <Account />
          ) : (
            <Navigate to="/login" replace />
          ),
        },
        {
          path: "history",
          element: ctx.isLoggedIn ? <HistoryComp /> : <Navigate to="/login" replace />,
        },
        {
          path: "my-bids",
          element: ctx.isLoggedIn ? <MyBids /> : <Navigate to="/login" replace />,
        },
        {
          path: "payment",
          element: ctx.isLoggedIn ? <CaptureCardDetails /> : <Navigate to="/login" replace />,
        },
        {
          path: 'checkout',
          element: ctx.isLoggedIn ? <BuyNowCheckout /> : <Navigate to="/login" replace />,
        },
        {
          path: "shipping-info",
          element: ctx.isLoggedIn ? <ShippingInformation /> : <Navigate to="/login" replace />
        },
        {
          path: "bag",
          element: ctx.isLoggedIn ? <ItemsBag /> : <Navigate to="/login" replace />
        },
        {
          path: "faq",
          element: ctx.isLoggedIn || guest_user_visited ? <FaqPage /> : <LoginGuest />,
        },
        {
          path: "support",
          element: ctx.isLoggedIn || guest_user_visited ? <SupportPage /> : <LoginGuest />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "tos",
          element: ctx.isLoggedIn || guest_user_visited ? <TermsPage /> : <TermsGuest />,
        },
        {
          path: "privacy",
          element: ctx.isLoggedIn || guest_user_visited ? <PrivacyPage /> : <LoginGuest />,
        },
        {
          path: "order/track/:orderId/",
          element: ctx.isLoggedIn ? <TrackOrder /> : <Navigate to="/login" replace />
        },
        // {
        //   path: "search",
        //   element: ctx.isLoggedIn || guest_user_visited ? <Search /> : <LoginGuest />,
        // },
        {
          path: "search",
          element: ctx.isLoggedIn || guest_user_visited ? <SearchResult /> : <LoginGuest />,
        },
        {
          path: 'browse-items',
          element: ctx.isLoggedIn || guest_user_visited ? <BrowseItems /> : <LoginGuest />,
        },
        {
          path: ':categoryId/browse-items', ///this is used for browse by category and browse by cause
          element: ctx.isLoggedIn || guest_user_visited ? <EventBrowseItems /> : <LoginGuest />,
        },
        {
          path: "cart",
          element:  ctx.isLoggedIn || guest_user_visited ? <CartOverView/> : <LoginGuest />,
          
        },
        {
          path: "favorites",
          element:  ctx.isLoggedIn || guest_user_visited ? <Favourites/> : <LoginGuest />,
          
        },
        {
          path: "cart-checkout",
          element:  ctx.isLoggedIn || guest_user_visited ? <CartCheckout/> : <LoginGuest />,
        },
        // {
        //   path: "event/:auctionId",
        //   element: ctx.isLoggedIn || guest_user_visited ? <Event /> : <LoginGuest />,

        // },
        {
          path: 'brand-partner',
          element: ctx.isLoggedIn || guest_user_visited ? <BrandPartner /> : <LoginGuest />,
        },
        {
          path: 'payment-methods',
          element: ctx.isLoggedIn ? <ExistingPayment /> : <Navigate to="/login" replace />
        },
        {
          path: 'create-event',
          element: ctx.isLoggedIn || guest_user_visited ? <CreateEvent /> : <LoginGuest />,
        },
        {
          path: 'deployment-status',
          element: <DeployedInfo />
        },
        {
          path: 'get-started',
          element: <LetsStartFunding />
        },
        {
          path: "coming-soon",
          element: <ComingSoon />,
        },
        {
          path: "not-found",
          element: <NotFound />,
        },
        {
          path: "preferences",
          element: ctx.isLoggedIn ?  <Preferences /> : <Navigate to="/login" replace />,
        },
        {
          path: "non-profit",
          children: [
            {
              path:"",
              element: ctx.isLoggedIn || guest_user_visited ?  <NonProfit /> : <LoginGuest/>,
            },
            {
              path: "create",
              element:  ctx.isLoggedIn || guest_user_visited ? <CreateNonProfit/> : <LoginGuest />,
            },
          ]
        },
        {
          path: "brand",
          element: ctx.isLoggedIn || guest_user_visited ? <Brand /> : <LoginGuest/>,
        },
        {
          path: "learn-more",
          element: ctx.isLoggedIn || guest_user_visited ? <LearnMore />: <LoginGuest/>,
        },
        {
          path: "retry-payment",
          element:  <RetryPayment />  
        },
      ],
    },
      { path: "*", element: <Navigate to="/not-found" replace /> }
  ])

  return ctx.isLoading ? loader : routes;
}
