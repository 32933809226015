import { config } from "../config/config"
const {ASSETS_BASE_URL} = config


// cart.jsx  && ItemsBag.jsx

const APP_COPY_NO_CARD_DETAILS = "You don't have a saved card"
const APP_COPY_PAYMENT_FAILED = 'Payment failed!'
const APP_COPY_ORDER_FAILED  = 'Failed to create order!'
const APP_COPY_ORDER_SALES_TAX_ERROR = 'Unable to calculate sales tax.'
 
// itemDetails.jsx

const APP_COPY_SELECT_CAUSE = 'Please select a cause of your choice'
const APP_COPY_RESERVE_PRICE_INFO = 'A reserve price is the minimum price the seller will accept. This price is hidden from bidders. To win, a bidder must have the highest bid and have met or exceeded the reserve price.'


// AuthorizePayment.jsx

const APP_COPY_INVALID_ZIP_CODE = 'Invalid Zip Code'
const APP_COPY_CARD_DETAILS_ERROR = 'Card details error, please check your card.'
const APP_COPY_COUNTRY_ERROR = 'Currently accepting only from United States!'
const APP_COPY_CART_NO_CARD_DETAILS = 'Please enter payment details'



// bidConfirmation.jsx

const APP_COPY_MAX_BID_PRICE = 'Your max bid amount is greater than buy price. Would you like to buy now?'
const APP_COPY_SAME_MAX_BID_VALUE_ALERT = 'The recomended amount is greater than $[].'

// shippingInformation.jsx

const APP_COPY_FAILED_SHIPPING_ADDRESS = 'Failed to save address information!'

// item image variants
const ITEM_MOBILE_VARIANT = 'mobile'

// shopnow pages
const SHOP_NOW_DESKTOP_IMAGE = `${ASSETS_BASE_URL}/User_2/Auction/b2249ab78228365b059334ef569b01b25a3d3a42155d8b1eec52b7cfce52293b`;
const SHOP_NOW_MOBILE_IMAGE = `${ASSETS_BASE_URL}/User_2/Auction/5871171abc2beec7b90fad714f7b87aee627c17ebc5147c4be40180438d73735`
const HOME_PAGE_SHOP_NOW_IMAGE = `${ASSETS_BASE_URL}/User_2/Auction/a6019caefe357fdfa8c235daac802dec586759ec772f27aeab9806b7d09b008d`;

const APP_COPY_FAVOURITE_EMPTY_MESSAGE = 'No favorites yet! Browse to save your favorites for later.'

// events page
const APP_COPY_EMPTY_EVENTS_PAGE = 'No events are happening at the moment, but keep shopping for awesome finds!'

// non-profits create
const APP_COPY_CAUSE_LANDSCAPE_IMAGE_NUMBER = 'Maximum number of landscape images are 3';
const APP_COPY_CAUSE_LAND_SCAPE_IMG_SIZE = 'Image is too small it should be at least 1908x954px.';
const APP_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE = 'File size should be less than or equal to 5MB';
const APP_COPY_CAUSE_VIDEO_LIMIT = 'You can upload maximum 1 videos'
const APP_COPY_CAUSE_LOGO_REQURIED = "A cause logo is required."
const APP_COPY_CAUSE_LOGO_SIZE= "Image is too small it should be at least 80x80PX"
const APP_COPY_CAUSE_LANDSCAPE_IMAGE_LABEL = 'Cause Cover Photo (1908x954px). Add up to three photos that visually represent your cause.'
const APP_COPY_CAUSE_COVER_IMG = 'Please upload at least 1 cause cover image!'
const APP_COPY_CAUSE_CATEGORY = 'Please select a category!'
const APP_COPY_CAUSE_CREATE_FAILURE = 'Failed to create cause!'
const APP_COPY_CAUSE_COVER_PHOTO = 'Add up to three photos that visually represent your cause'
const APP_COPY_CAUSE_STATE = 'Please select a State!'
const APP_COPY_CAUSE_FIRST_NAME = 'First name should not have spaces in between'
const APP_COPY_CAUSE_LAST_NAME = 'Last name should not have spaces in between'
const APP_COPY_CAUSE_ZIP_CODE = 'Please provide a valid zip code!'
const APP_COPY_CREATE_CAUSE_SUCCESS = 'Non Profit created successfully!'
const APP_COPY_CAUSE_VIDEO_SIZE = 'Maximum allowed size of video is 20Mb.'

// common
const APP_COPY_SOMETHING_WENT_WRONG = 'Something went wrong!'
const APP_SOCIAL_LINKS = {
    FACEBOOK_LINK: 'https://www.facebook.com/boundlessgivingapp',
    TWITER: "https://twitter.com/BoundlessGiving",
    INSTAGRAM: 'https://www.instagram.com/boundlessgiving/'
}
const STRIPE_LINKS = {
    TERMS_LINK: 'https://stripe.com/legal/link-account-terms',
    POLICY_LINK: 'https://stripe.com/privacy'
}

const APP_HOME_PAGE_COPIES = {
    aboutSection: ['At Boundless Giving, we believe that making a difference should be simple and direct.', "A big part of our purpose is making sure that as much of the money spent on goods from consumers like you goes directly to the charities we represent.", "We guarantee that 50-80% of a net marketplace and event proceeds will be donated directly to the causes we promote on our platform."]
}

const APP_FOOTER_COPIES = {
    para: "Boundless Giving is an online marketplace and software solution designed to help nonprofits increase fundraising impact and expand their donor bases.",
    subscribePara: "By clicking Subscribe you're confirming that you agree with our Terms.",
    pageLinks: [
        {
            label: 'SHOP',
            link: '/shop-now'
        },
        {
            label: 'ABOUT',
            link: '/about'
        },
        {
            label: 'FOR BRANDS',
            link: '/brand'
        },
        {
            label: 'FOR NONPROFITS',
            link: '/non-profit'
        },
        {
            label: 'EVENTS',
            link: '/auction'
        },
        {
            label: 'FAQ',
            link: '/faq'
        },
    ],
    CopyrightText: `© ${new Date().getFullYear()} Boundless Giving. All Rights Reserved.`,
}

const APP_HEADER_COPIES = {
    pageLinks: [
        {
            label: 'About',
            link: '/about'
        },
        {
            label: 'Shop By Brand',
            link: '/browse-brands'
        },
        {
            label: 'Shop By Cause',
            link: '/browse-stores'
        },
        {
            label: 'Events',
            link: '/auction'
        },
        {
            label: 'Blog',
            link: '/blog'
        }
    ]
}

const constants={
    APP_COPY_CARD_DETAILS_ERROR,
    APP_COPY_COUNTRY_ERROR,
    APP_COPY_FAILED_SHIPPING_ADDRESS,
    APP_COPY_INVALID_ZIP_CODE,
    APP_COPY_MAX_BID_PRICE,
    APP_COPY_NO_CARD_DETAILS,
    APP_COPY_ORDER_FAILED,
    APP_COPY_SELECT_CAUSE,
    APP_COPY_PAYMENT_FAILED,
    APP_COPY_CART_NO_CARD_DETAILS,
    ITEM_MOBILE_VARIANT,
    SHOP_NOW_DESKTOP_IMAGE,
    HOME_PAGE_SHOP_NOW_IMAGE,
    SHOP_NOW_MOBILE_IMAGE,
    APP_COPY_SOMETHING_WENT_WRONG,
    APP_COPY_FAVOURITE_EMPTY_MESSAGE,
    APP_COPY_EMPTY_EVENTS_PAGE,
    APP_COPY_CAUSE_LANDSCAPE_IMAGE_NUMBER,
    APP_COPY_CAUSE_LAND_SCAPE_IMG_SIZE,
    APP_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE,
    APP_COPY_CAUSE_LOGO_SIZE,
    APP_COPY_CAUSE_LOGO_REQURIED,
    APP_COPY_CAUSE_VIDEO_LIMIT,
    APP_COPY_CAUSE_LANDSCAPE_IMAGE_LABEL,
    APP_COPY_CAUSE_COVER_PHOTO,
    APP_COPY_CAUSE_CREATE_FAILURE,
    APP_COPY_CAUSE_CATEGORY,
    APP_COPY_CAUSE_COVER_IMG,
    APP_COPY_CAUSE_STATE,
    APP_COPY_CAUSE_FIRST_NAME,
    APP_COPY_CAUSE_LAST_NAME,
    APP_COPY_CAUSE_ZIP_CODE,
    APP_COPY_CREATE_CAUSE_SUCCESS,
    APP_COPY_CAUSE_VIDEO_SIZE,
    APP_HOME_PAGE_COPIES,
    APP_FOOTER_COPIES,
    APP_SOCIAL_LINKS,
    APP_HEADER_COPIES,
    APP_COPY_RESERVE_PRICE_INFO,
    STRIPE_LINKS,
    APP_COPY_ORDER_SALES_TAX_ERROR,
    APP_COPY_SAME_MAX_BID_VALUE_ALERT
}

export default constants